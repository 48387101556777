import each from 'lodash/each';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError as globalSetError } from '@/store/setError';

const {
  getPromotion,
  postPromotion,
  putPromotion,
  deletePromotion,
} = backOffice.promotions;

export const PROMOTION_SCOPES = {
  promotion: 'promotion',
  newPromotion: 'newPromotion',
  deletePromotion: 'deletePromotion',
};

const promotions = createCustomStore(({ runAsyncFlow }) => withScopes(PROMOTION_SCOPES, ({
  state: {
    [PROMOTION_SCOPES.newPromotion]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === PROMOTION_SCOPES.deletePromotion) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    clearErrors(state) {
      each(PROMOTION_SCOPES, scope => {
        state[scope].error = null;
        state[scope].STATUS = { ...INITIAL_STATUS };
      });
    },
  },
  actions: {
    async getPromotion({ commit }, promotionUuid) {
      await runAsyncFlow(commit, {
        request: getPromotion,
        params: [promotionUuid],
        scope: PROMOTION_SCOPES.promotion,
      });
    },
    async postPromotion({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postPromotion,
        params: [data],
        scope: PROMOTION_SCOPES.newPromotion,
      });
    },
    async putPromotion({ commit }, { promotionUuid, data }) {
      await runAsyncFlow(commit, {
        request: putPromotion,
        params: [promotionUuid, data],
        scope: PROMOTION_SCOPES.newPromotion,
      });
    },
    async deletePromotion({ commit }, promotionUuid) {
      await runAsyncFlow(commit, {
        request: deletePromotion,
        params: [promotionUuid],
        scope: PROMOTION_SCOPES.deletePromotion,
      });
    },
  },
})));

export default promotions;
